<template>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        :allow-selection="false"
        :columns="columns"
        :pagination="false"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1640, y: gridHeight }"
      >
        <template #enable="{ record }">
          <a-switch
              v-model:checked="record.enable"
              checked-children="启用"
              un-checked-children="禁用"
              @click="handleClickSwitch(record)"
          />
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
      </grid>
      <a-modal
        v-model:visible="visible"
        :title="!form.id ? '新增' : '编辑'"
        cancelText="取消"
        okText="确认"
        :width="600"
        centered
        @ok="handleOk"
      >
        <a-form
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          :model="form"
        >
          <a-form-item
            label="选择分类"
            v-bind="validateInfos.packageName"
          >
          <a-select
                v-model:value="form.packageName"
                :options="classifyOptions"
                placeholder="请选择分类"
              />
          </a-form-item>
          <a-form-item
              label="使用天数"
              v-bind="validateInfos.packageDuration"
              class="flex-row"
          >
            <a-input v-model:value="form.packageDuration" placeholder="使用天数" />
            <text class="unit">天</text>
          </a-form-item>
          <a-form-item
            label="所需金币"
            v-bind="validateInfos.packageMoney"
            class="flex-row"
          >
            <a-input v-model:value="form.packageMoney" placeholder="请输入所需金币" />
            <text class="unit">个</text>
          </a-form-item>
          <a-form-item label="描述" v-bind="validateInfos.packageDesc">
        <a-textarea v-model:value="form.packageDesc" placeholder="请输入描述" />
      </a-form-item>
          <a-form-item label="启用状态">
            <a-switch
              v-model:checked="form.enable"
              checked-children="启用"
              un-checked-children="禁用"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </template>
    <script>
  import { reactive, toRefs, ref,toRaw } from 'vue';
  import Grid from '@/components/grid';
  import Operation from '@/components/operation';
  import { Form, Switch,message , Select,} from 'ant-design-vue';
  import configurationApi from '@/api/configuration.js';
  export default {
    components: {
      Operation,
      Grid,
      AForm: Form,
      AFormItem: Form.Item,
      ASwitch: Switch,
      ASelect: Select,

    },
    setup() {
      const gridRef = ref();
      const state = reactive({
        search: {
          type:2
        },
        TagList: [],
        visible: false,
        classifyOptions:[
          {
            value: '使用时长',
            label: '使用时长',
          },
        ]
      });
      const form= reactive({
        id:'',
          packageName: null,
        packageDuration: '',
          packageMoney:'',
          packageDesc:'',
          enable: true,
        })
      const required = { required: true, message: '不能为空' };
      const checkZero = (rule, value) => {
      let num = Number(value)
      if (value && !(!isNaN(num) && num > 0)) {
        return Promise.reject('输入值必须为大于0');
      }else if(num>9999){
        return Promise.reject('输入值必须为小于10000的整数');
      }
      return Promise.resolve()
    };
      const { resetFields, validate, validateInfos } = Form.useForm(form, {
        packageName: [required],
        packageDuration: [required, {
          validator: checkZero ,
          trigger: ['blur', 'change'],
        },],
        packageMoney:[required, {
          validator:checkZero ,
          trigger: ['blur', 'change'],
        },],
        packageDesc:[required]
      });
      return {
        gridRef,
        ...toRefs(state),
        form,
        validateInfos,
        items: [
          { key: 'packageName', label: '名称' },
          { key: 'packageDuration', label: '使用天数' },
          { key: 'packageMoney', label: '金额' },
          { key: 'packageDesc', label: '描述' },
          { key: 'createTime', label: '创建时间' },
          { key: 'updateTime', label: '最后更新时间' },
          {
            key: 'enable',
            label: '启用状态',
            dataset: [
              { value: '0', label: '启用' },
              { value: '1', label: '禁用' },
            ],
          },
        ],
        columns: [
          {
            dataIndex: '',
            title: '序号',
            width: 80,
             ellipsis: true,   align: 'center',
            customRender: ({ index }) => `${index + 1}`,
          },
          {
            dataIndex: 'packageName',
            title: '名称',
            width: 100,
             ellipsis: true,   align: 'center',
          },
          { dataIndex: 'packageMoney', title: '所需金币', width: 100,  ellipsis: true,   align: 'center', },
          { dataIndex: 'packageDuration', title: '使用天数', width: 100,  ellipsis: true,   align: 'center', },
          { dataIndex: 'packageDesc', title: '描述', width: 200,  ellipsis: true,   align: 'center', },
          { dataIndex: 'createTime', title: '创建时间时间', width: 180,  ellipsis: true,   align: 'center', },
          { dataIndex: 'updateTime', title: '最后更新时间', width: 180,  ellipsis: true,   align: 'center', },
          {
            dataIndex: 'enable',
            title: '启用状态',
            width: 140,
            slots: { customRender: 'enable' },
             ellipsis: true,   align: 'center',
          },
          {
            key: 'id',
            title: '操作',
            fixed: 'right',
            width: 60,
            align: 'center',
            slots: { customRender: 'operation' },
          },
        ],
        btnOperation: [
          {
            type: 'add',
            label: '',
            icon: 'PlusOutlined',
            permission: 'goldMall:add',
            fnClick: () => {
            state.visible = true;
            resetFields();
            console.log(form,77)
            },
          },
        ],
        options: [
          {
            type: 'update',
            label: '修改',
            icon: 'EditTwoTone',
            permission: 'goldMall:update',
            fnClick: record => {
            resetFields();
            state.visible = true;
            Object.assign(form, record);
            },
          },
          {
            type: 'delete',
            label: '删除',
            icon: 'DeleteTwoTone',
            permission: 'goldMall:delete',
            fnClick: ({id}) => {
            configurationApi.delete(`configuration:delete`, { ids: [id] }).then(()=>{
            state.visible = false;
            message.success('操作成功');
            gridRef.value.refreshGrid();
          })
            },
          },
        ],
        url:configurationApi.pageUrl,
        gridHeight: document.body.clientHeight - 386,
        handleOk() {
          validate().then(() => {
          let type = 'add';
          form?.id && (type = 'update');
          let data ={...toRaw(form)}
          data.goldNumber=data.packageMoney
          data.packageTitle= data.packageDesc
          data.durationUnit='DAY'
          configurationApi[type](`configuration:${type}`, {packageType:state.search.type,...data}).then(()=>{
            state.visible = false;
            message.success('操作成功');
            gridRef.value.refreshGrid();
          })
        });
        },
        handleClickSwitch(record) {
          configurationApi.updateStatus('', {
            id: record.id,
            enable: record.enable
          }).then((res) => {
            if (res) {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            }
          });
        }
      };
    },
  };
  </script>
    <style lang="less" scoped>

  
  .flex-row {
    /deep/.ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      position: relative;
.unit{
  position: absolute;
  top: 50%;
  right: -8%;
  transform: translateY(-50%);
}
    }
  }
  /deep/ .ant-table-body{
    max-height: 70vh !important;
  }
  </style>